import { template as template_abc3baa239a7459bbfdf76bd80cb4c5d } from "@ember/template-compiler";
const WelcomeHeader = template_abc3baa239a7459bbfdf76bd80cb4c5d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
