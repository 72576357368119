import { template as template_706ebc5574524961bc2b76b04b7d3b61 } from "@ember/template-compiler";
const SidebarSectionMessage = template_706ebc5574524961bc2b76b04b7d3b61(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
